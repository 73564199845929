// Site TakeOver
$(document).ready(function() {
	let campaignTheme = $('#themeId').attr('data-value');
	$('#toggleTheme').change(function() {
		let themeName = $(this).is(':checked') ? campaignTheme : 'Rac';
		let url = ACC.config.encodedContextPath+'/theme?themeName='+themeName;
		let animTime = $('#themeAnimationConfigs').data('theme-animation-time');

		// Show Theme Specific Animation
		if ($(this).is(':checked')) {
			$('.theme-switching-animation').fadeIn(250);
			setTimeout(function() {
				triggerThemeSwitcher();
			}, Number(animTime));
		} else {
			handleSpinner();
			RAC.loadingSpinnerShow();
			triggerThemeSwitcher();
		}

		function triggerThemeSwitcher() {
			$.ajax({
				url: url,
				type: 'POST',
				contentType: 'application/json',
				data: JSON.stringify({ theme: themeName }),
				success: function(response) {
					location.reload();
				},
				error: function(xhr, status, error) {
					console.log('AJAX request failed:', status, error);
				}
			});
		}
	});
});

$(window).on('load', function() {
	// Preload the img in memory after page loads
	let imgElement = $('.theme-switching-animation img');
	let animationUrl = imgElement.data('src');

	// Attach a load event to the image
	imgElement.on('load', function() {
		$('.site-take-over-switch .theme-switch input').removeAttr('disabled');
	});

	// Set the src to start loading the image
	imgElement.attr('src', animationUrl);
});